/*	Animations v1.0, Copyright 2014, Joe Mottershaw, https://github.com/joemottershaw/
//	================================================================================== */
@-moz-document url-prefix() {
  .animate, .onHoverBounce, .onHoverExplode, .onHoverFlash, .onHoverHeartbeat, .onHoverPanic, .onHoverPullback, .onHoverPulsate, .onHoverPulse, .onHoverShake, .onHoverSpin, .onHoverStrobe, .onHoverTada, .onHoverWave, .onHoverWobble, .trigger {
    opacity: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .ie .animate, .no-js .animate, .onHoverBounce, .onHoverExplode, .onHoverFlash, .onHoverHeartbeat, .onHoverPanic, .onHoverPullback, .onHoverPulsate, .onHoverPulse, .onHoverShake, .onHoverSpin, .onHoverStrobe, .onHoverTada, .onHoverWave, .onHoverWobble, .trigger {
    opacity: 1; }
  .animate.infinite, .onHoverBounce.infinite, .onHoverExplode.infinite, .onHoverFlash.infinite, .onHoverHeartbeat.infinite, .onHoverPanic.infinite, .onHoverPullback.infinite, .onHoverPulsate.infinite, .onHoverPulse.infinite, .onHoverShake.infinite, .onHoverSpin.infinite, .onHoverStrobe.infinite, .onHoverTada.infinite, .onHoverWave.infinite, .onHoverWobble.infinite, .trigger.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  .animate.fadeIn, .trigger.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn; }
  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(100px); }
    100% {
      opacity: 1;
      transform: translateY(0); } }
  .animate.fadeInUp, .trigger.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp; }
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-100px); }
    100% {
      opacity: 1;
      transform: translateY(0); } }
  .animate.fadeInDown, .trigger.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown; }
  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-200px); }
    100% {
      opacity: 1;
      transform: translateX(0); } }
  .animate.fadeInLeft, .trigger.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft; }
  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(200px); }
    100% {
      opacity: 1;
      transform: translateX(0); } }
  .animate.fadeInRight, .trigger.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight; }
  @-webkit-keyframes fadeInUpLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateY(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInUpLarge {
    0% {
      opacity: 0;
      transform: translateY(1000px); }
    100% {
      opacity: 1;
      transform: translateY(0); } }
  .animate.fadeInUpLarge, .trigger.fadeInUpLarge {
    -webkit-animation-name: fadeInUpLarge;
    animation-name: fadeInUpLarge; }
  @-webkit-keyframes fadeInDownLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInDownLarge {
    0% {
      opacity: 0;
      transform: translateY(-1000px); }
    100% {
      opacity: 1;
      transform: translateY(0); } }
  .animate.fadeInDownLarge, .trigger.fadeInDownLarge {
    -webkit-animation-name: fadeInDownLarge;
    animation-name: fadeInDownLarge; }
  @-webkit-keyframes fadeInLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInLeftLarge {
    0% {
      opacity: 0;
      transform: translateX(-1000px); }
    100% {
      opacity: 1;
      transform: translateX(0); } }
  .animate.fadeInLeftLarge, .trigger.fadeInLeftLarge {
    -webkit-animation-name: fadeInLeftLarge;
    animation-name: fadeInLeftLarge; }
  @-webkit-keyframes fadeInRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInRightLarge {
    0% {
      opacity: 0;
      transform: translateX(1000px); }
    100% {
      opacity: 1;
      transform: translateX(0); } }
  .animate.fadeInRightLarge, .trigger.fadeInRightLarge {
    -webkit-animation-name: fadeInRightLarge;
    animation-name: fadeInRightLarge; }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  @keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  .animate.fadeOut, .trigger.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut; }
  @-webkit-keyframes fadeOutUp {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200px); } }
  @keyframes fadeOutUp {
    0% {
      opacity: 1;
      transform: translateY(0); }
    100% {
      opacity: 0;
      transform: translateY(-200px); } }
  .animate.fadeOutUp, .trigger.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp; }
  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(200px); } }
  @keyframes fadeOutDown {
    0% {
      opacity: 1;
      transform: translateY(0); }
    100% {
      opacity: 0;
      transform: translateY(200px); } }
  .animate.fadeOutDown, .trigger.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown; }
  @-webkit-keyframes fadeOutLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-200px); } }
  @keyframes fadeOutLeft {
    0% {
      opacity: 0;
      transform: translateX(0); }
    100% {
      opacity: 1;
      transform: translateX(-200px); } }
  .animate.fadeOutLeft, .trigger.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft; }
  @-webkit-keyframes fadeOutRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(200px); } }
  @keyframes fadeOutRight {
    0% {
      opacity: 0;
      transform: translateX(0); }
    100% {
      opacity: 1;
      transform: translateX(200px); } }
  .animate.fadeOutRight, .trigger.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight; }
  @-webkit-keyframes fadeOutUpLarge {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-1000px); } }
  @keyframes fadeOutUpLarge {
    0% {
      opacity: 1;
      transform: translateY(0); }
    100% {
      opacity: 0;
      transform: translateY(-1000px); } }
  .animate.fadeOutUpLarge, .trigger.fadeOutUpLarge {
    -webkit-animation-name: fadeOutUpLarge;
    animation-name: fadeOutUpLarge; }
  @-webkit-keyframes fadeOutDownLarge {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(1000px); } }
  @keyframes fadeOutDownLarge {
    0% {
      opacity: 1;
      transform: translateY(0); }
    100% {
      opacity: 0;
      transform: translateY(1000px); } }
  .animate.fadeOutDownLarge, .trigger.fadeOutDownLarge {
    -webkit-animation-name: fadeOutDownLarge;
    animation-name: fadeOutDownLarge; }
  @-webkit-keyframes fadeOutLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-1000px); } }
  @keyframes fadeOutLeftLarge {
    0% {
      opacity: 0;
      transform: translateX(0); }
    100% {
      opacity: 1;
      transform: translateX(-1000px); } }
  .animate.fadeOutLeftLarge, .trigger.fadeOutLeftLarge {
    -webkit-animation-name: fadeOutLeftLarge;
    animation-name: fadeOutLeftLarge; }
  @-webkit-keyframes fadeOutRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(1000px); } }
  @keyframes fadeOutRightLarge {
    0% {
      opacity: 0;
      transform: translateX(0); }
    100% {
      opacity: 1;
      transform: translateX(1000px); } }
  .animate.fadeOutRightLarge, .trigger.fadeOutRightLarge {
    -webkit-animation-name: fadeOutRightLarge;
    animation-name: fadeOutRightLarge; }
  @-webkit-keyframes zoomIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1); } }
  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale(0); }
    100% {
      opacity: 1;
      transform: scale(1); } }
  .animate.zoomIn, .trigger.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn; }
  @-webkit-keyframes zoomInUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInUp {
    0% {
      opacity: 0;
      transform: scale(0) translateY(200px); }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0); } }
  .animate.zoomInUp, .trigger.zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp; }
  @-webkit-keyframes zoomInDown {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInDown {
    0% {
      opacity: 0;
      transform: scale(0) translateY(-200px); }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0); } }
  .animate.zoomInDown, .trigger.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown; }
  @-webkit-keyframes zoomInLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInLeft {
    0% {
      opacity: 0;
      transform: scale(0) translateX(-200px); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0); } }
  .animate.zoomInLeft, .trigger.zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft; }
  @-webkit-keyframes zoomInRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInRight {
    0% {
      opacity: 0;
      transform: scale(0) translateX(200px); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0); } }
  .animate.zoomInRight, .trigger.zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight; }
  @-webkit-keyframes zoomInUpLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInUpLarge {
    0% {
      opacity: 0;
      transform: scale(0) translateY(1000px); }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0); } }
  .animate.zoomInUpLarge, .trigger.zoomInUpLarge {
    -webkit-animation-name: zoomInUpLarge;
    animation-name: zoomInUpLarge; }
  @-webkit-keyframes zoomInDownLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInDownLarge {
    0% {
      opacity: 0;
      transform: scale(0) translateY(-1000px); }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0); } }
  .animate.zoomInDownLarge, .trigger.zoomInDownLarge {
    -webkit-animation-name: zoomInDownLarge;
    animation-name: zoomInDownLarge; }
  @-webkit-keyframes zoomInLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInLeftLarge {
    0% {
      opacity: 0;
      transform: scale(0) translateX(-1000px); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0); } }
  .animate.zoomInLeftLarge, .trigger.zoomInLeftLarge {
    -webkit-animation-name: zoomInLeftLarge;
    animation-name: zoomInLeftLarge; }
  @-webkit-keyframes zoomInRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInRightLarge {
    0% {
      opacity: 0;
      transform: scale(0) translateX(1000px); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0); } }
  .animate.zoomInRightLarge, .trigger.zoomInRightLarge {
    -webkit-animation-name: zoomInRightLarge;
    animation-name: zoomInRightLarge; }
  @-webkit-keyframes zoomOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0); } }
  @keyframes zoomOut {
    0% {
      opacity: 1;
      transform: scale(1); }
    100% {
      opacity: 0;
      transform: scale(0); } }
  .animate.zoomOut, .trigger.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut; }
  @-webkit-keyframes zoomOutUp {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px); } }
  @keyframes zoomOutUp {
    0% {
      opacity: 1;
      transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      transform: scale(0) translateY(-200px); } }
  .animate.zoomOutUp, .trigger.zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp; }
  @-webkit-keyframes zoomOutDown {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px); } }
  @keyframes zoomOutDown {
    0% {
      opacity: 1;
      transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      transform: scale(0) translateY(200px); } }
  .animate.zoomOutDown, .trigger.zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown; }
  @-webkit-keyframes zoomOutLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(-200px); } }
  @keyframes zoomOutLeft {
    0% {
      opacity: 0;
      transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(-200px); } }
  .animate.zoomOutLeft, .trigger.zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft; }
  @-webkit-keyframes zoomOutRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(200px); } }
  @keyframes zoomOutRight {
    0% {
      opacity: 0;
      transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(200px); } }
  .animate.zoomOutRight, .trigger.zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight; }
  @-webkit-keyframes zoomOutUpLarge {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-1000px); } }
  @keyframes zoomOutUpLarge {
    0% {
      opacity: 1;
      transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      transform: scale(0) translateY(-1000px); } }
  .animate.zoomOutUpLarge, .trigger.zoomOutUpLarge {
    -webkit-animation-name: zoomOutUpLarge;
    animation-name: zoomOutUpLarge; }
  @-webkit-keyframes zoomOutDownLarge {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(1000px); } }
  @keyframes zoomOutDownLarge {
    0% {
      opacity: 1;
      transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      transform: scale(0) translateY(1000px); } }
  .animate.zoomOutDownLarge, .trigger.zoomOutDownLarge {
    -webkit-animation-name: zoomOutDownLarge;
    animation-name: zoomOutDownLarge; }
  @-webkit-keyframes zoomOutLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(-1000px); } }
  @keyframes zoomOutLeftLarge {
    0% {
      opacity: 0;
      transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(-1000px); } }
  .animate.zoomOutLeftLarge, .trigger.zoomOutLeftLarge {
    -webkit-animation-name: zoomOutLeftLarge;
    animation-name: zoomOutLeftLarge; }
  @-webkit-keyframes zoomOutRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(1000px); } }
  @keyframes zoomOutRightLarge {
    0% {
      opacity: 0;
      transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(1000px); } }
  .animate.zoomOutRightLarge, .trigger.zoomOutRightLarge {
    -webkit-animation-name: zoomOutRightLarge;
    animation-name: zoomOutRightLarge; }
  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0); }
    50% {
      opacity: 1;
      -webkit-transform: scale(1.1); }
    70% {
      opacity: 1;
      -webkit-transform: scale(0.9); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1); } }
  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0); }
    50% {
      opacity: 1;
      transform: scale(1.1); }
    70% {
      opacity: 1;
      transform: scale(0.9); }
    100% {
      opacity: 1;
      transform: scale(1); } }
  .animate.bounceIn, .trigger.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn; }
  @-webkit-keyframes bounceInUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateY(-40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes bounceInUp {
    0% {
      opacity: 0;
      transform: scale(0) translateY(200px); }
    60% {
      opacity: 1;
      transform: scale(1.1) translateY(-40px); }
    80% {
      opacity: 1;
      transform: scale(0.9) translateY(20px); }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0); } }
  .animate.bounceInUp, .trigger.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp; }
  @-webkit-keyframes bounceInDown {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateY(40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(-20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes bounceInDown {
    0% {
      opacity: 0;
      transform: scale(0) translateY(-200px); }
    60% {
      opacity: 1;
      transform: scale(1.1) translateY(40px); }
    80% {
      opacity: 1;
      transform: scale(0.9) translateY(-20px); }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0); } }
  .animate.bounceInDown, .trigger.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown; }
  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateX(40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateX(-20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: scale(0) translateX(-200px); }
    60% {
      opacity: 1;
      transform: scale(1.1) translateX(40px); }
    80% {
      opacity: 1;
      transform: scale(0.9) translateX(-20px); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0); } }
  .animate.bounceInLeft, .trigger.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft; }
  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateX(-40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateX(20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: scale(0) translateX(200px); }
    60% {
      opacity: 1;
      transform: scale(1.1) translateX(-40px); }
    80% {
      opacity: 1;
      transform: scale(0.9) translateX(20px); }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0); } }
  .animate.bounceInRight, .trigger.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight; }
  @-webkit-keyframes bounceOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1); }
    30% {
      opacity: 1;
      -webkit-transform: scale(1.1); }
    50% {
      opacity: 0;
      -webkit-transform: scale(0.9); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0); } }
  @keyframes bounceOut {
    0% {
      opacity: 1;
      transform: scale(1); }
    30% {
      opacity: 1;
      transform: scale(1.1); }
    50% {
      opacity: 0;
      transform: scale(0.9); }
    100% {
      opacity: 0;
      transform: scale(0); } }
  .animate.bounceOut, .trigger.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut; }
  @-webkit-keyframes bounceOutUp {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateY(40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200px); } }
  @keyframes bounceOutUp {
    0% {
      opacity: 1;
      transform: translateY(0); }
    20% {
      opacity: 1;
      transform: translateY(40px); }
    100% {
      opacity: 0;
      transform: translateY(-200px); } }
  .animate.bounceOutUp, .trigger.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp; }
  @-webkit-keyframes bounceOutDown {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateY(-40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(200px); } }
  @keyframes bounceOutDown {
    0% {
      opacity: 1;
      transform: translateY(0); }
    20% {
      opacity: 1;
      transform: translateY(-40px); }
    100% {
      opacity: 0;
      transform: translateY(200px); } }
  .animate.bounceOutDown, .trigger.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown; }
  @-webkit-keyframes bounceOutLeft {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateX(40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-200px); } }
  @keyframes bounceOutLeft {
    0% {
      opacity: 1;
      transform: translateX(0); }
    20% {
      opacity: 1;
      transform: translateX(40px); }
    100% {
      opacity: 0;
      transform: translateX(-200px); } }
  .animate.bounceOutLeft, .trigger.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft; }
  @-webkit-keyframes bounceOutRight {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateX(-40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateX(200px); } }
  @keyframes bounceOutRight {
    0% {
      opacity: 1;
      transform: translateX(0); }
    20% {
      opacity: 1;
      transform: translateX(-40px); }
    100% {
      opacity: 0;
      transform: translateX(200px); } }
  .animate.bounceOutRight, .trigger.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight; }
  @-webkit-keyframes spinLeft {
    0% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(-380deg);
      opacity: 0; }
    100% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(0);
      opacity: 1; } }
  @keyframes spinLeft {
    0% {
      transform-origin: center center;
      transform: rotate(-380deg);
      opacity: 0; }
    100% {
      transform-origin: center center;
      transform: rotate(0);
      opacity: 1; } }
  .animate.spinLeft, .trigger.spinLeft {
    -webkit-animation-name: spinLeft;
    animation-name: spinLeft; }
  @-webkit-keyframes spinRight {
    0% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(380deg);
      opacity: 0; }
    100% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(0);
      opacity: 1; } }
  @keyframes spinRight {
    0% {
      transform-origin: center center;
      transform: rotate(380deg);
      opacity: 0; }
    100% {
      transform-origin: center center;
      transform: rotate(0);
      opacity: 1; } }
  .animate.spinRight, .trigger.spinRight {
    -webkit-animation-name: spinRight;
    animation-name: spinRight; }
  @-webkit-keyframes flash {
    0%, 100%, 50% {
      opacity: 1; }
    25%, 75% {
      opacity: 0; } }
  @keyframes flash {
    0%, 100%, 50% {
      opacity: 1; }
    25%, 75% {
      opacity: 0; } }
  .animate.flash, .onHoverFlash:hover, .trigger.flash {
    -webkit-animation-name: flash;
    animation-name: flash; }
  @-webkit-keyframes strobe {
    0%, 100%, 20%, 40%, 60%, 80% {
      opacity: 1; }
    10%, 30%, 50%, 70%, 90% {
      opacity: 0; } }
  @keyframes strobe {
    0%, 100%, 20%, 40%, 60%, 80% {
      opacity: 1; }
    10%, 30%, 50%, 70%, 90% {
      opacity: 0; } }
  .animate.strobe, .onHoverStrobe:hover, .trigger.strobe {
    -webkit-animation-name: strobe;
    animation-name: strobe; }
  @-webkit-keyframes shake {
    0%, 100% {
      -webkit-transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% {
      -webkit-transform: translateX(-10px); }
    20%, 40%, 60%, 80% {
      -webkit-transform: translateX(10px); } }
  @keyframes shake {
    0%, 100% {
      transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-10px); }
    20%, 40%, 60%, 80% {
      transform: translateX(10px); } }
  .animate.shake, .onHoverShake:hover, .trigger.shake {
    -webkit-animation-name: shake;
    animation-name: shake; }
  @-webkit-keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
      -webkit-transform: translateY(0); }
    40% {
      -webkit-transform: translateY(-30px); }
    60% {
      -webkit-transform: translateY(-15px); } }
  @keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
      transform: translateY(0); }
    40% {
      transform: translateY(-30px); }
    60% {
      transform: translateY(-15px); } }
  .animate.bounce, .onHoverBounce:hover, .trigger.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce; }
  @-webkit-keyframes tada {
    0%, 100% {
      -webkit-transform: scale(1) rotate(0); }
    10%, 20% {
      -webkit-transform: scale(0.9) rotate(-3deg); }
    30%, 50%, 70%, 90% {
      -webkit-transform: scale(1.1) rotate(3deg); }
    40%, 60%, 80% {
      -webkit-transform: scale(1.1) rotate(-3deg); } }
  @keyframes tada {
    0%, 100% {
      transform: scale(1) rotate(0); }
    10%, 20% {
      transform: scale(0.9) rotate(-3deg); }
    30%, 50%, 70%, 90% {
      transform: scale(1.1) rotate(3deg); }
    40%, 60%, 80% {
      transform: scale(1.1) rotate(-3deg); } }
  .animate.tada, .onHoverTada:hover, .trigger.tada {
    -webkit-animation-name: tada;
    animation-name: tada; }
  @-webkit-keyframes wave {
    20% {
      -webkit-transform: rotate(15deg); }
    40% {
      -webkit-transform: rotate(-10deg); }
    60% {
      -webkit-transform: rotate(5deg); }
    80% {
      -webkit-transform: rotate(-5deg); }
    100% {
      -webkit-transform: rotate(0deg); } }
  @keyframes wave {
    20% {
      transform: rotate(15deg); }
    40% {
      transform: rotate(-10deg); }
    60% {
      transform: rotate(5deg); }
    80% {
      transform: rotate(-5deg); }
    100% {
      transform: rotate(0deg); } }
  .animate.wave, .onHoverWave:hover, .trigger.wave {
    -webkit-animation-name: wave;
    animation-name: wave; }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes spin {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  .animate.spin, .onHoverSpin:hover, .trigger.spin {
    -webkit-animation-name: spin;
    animation-name: spin; }
  @-webkit-keyframes pullback {
    0% {
      -webkit-transform: rotate(0deg); }
    20%, 30% {
      -webkit-transform: rotate(-45deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes pullback {
    0% {
      transform: rotate(0deg); }
    20%, 30% {
      transform: rotate(-45deg); }
    100% {
      transform: rotate(360deg); } }
  .animate.pullback, .onHoverPullback:hover, .trigger.pullback {
    -webkit-animation-name: pullback;
    animation-name: pullback; }
  @-webkit-keyframes wobble {
    0% {
      -webkit-transform: translateX(0px); }
    15% {
      -webkit-transform: translateX(-25px) rotate(-5deg); }
    30% {
      -webkit-transform: translateX(20px) rotate(3deg); }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3deg); }
    60% {
      -webkit-transform: translateX(10px) rotate(2deg); }
    75% {
      -webkit-transform: translateX(-5px) rotate(-1deg); }
    100% {
      -webkit-transform: translateX(0px); } }
  @keyframes wobble {
    0% {
      transform: translateX(0px); }
    15% {
      transform: translateX(-25px) rotate(-5deg); }
    30% {
      transform: translateX(20px) rotate(3deg); }
    45% {
      transform: translateX(-15px) rotate(-3deg); }
    60% {
      transform: translateX(10px) rotate(2deg); }
    75% {
      transform: translateX(-5px) rotate(-1deg); }
    100% {
      transform: translateX(0px); } }
  .animate.wobble, .onHoverWobble:hover, .trigger.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble; }
  @-webkit-keyframes pulse {
    0%, 100% {
      -webkit-transform: scale(1); }
    50% {
      -webkit-transform: scale(1.1); } }
  @keyframes pulse {
    0%, 100% {
      transform: scale(1); }
    50% {
      transform: scale(1.1); } }
  .animate.pulse, .onHoverPulse:hover, .trigger.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse; }
  @-webkit-keyframes pulsate {
    0%, 100%, 50% {
      -webkit-transform: scale(1); }
    25%, 75% {
      -webkit-transform: scale(1.1); } }
  @keyframes pulsate {
    0%, 100%, 50% {
      transform: scale(1); }
    25%, 75% {
      transform: scale(1.1); } }
  .animate.pulsate, .onHoverPulsate:hover, .trigger.pulsate {
    -webkit-animation-name: pulsate;
    animation-name: pulsate; }
  @-webkit-keyframes heartbeat {
    0%, 30%, 50%, 60%, 80% {
      -webkit-transform: scale(1); }
    40%, 70% {
      -webkit-transform: scale(1.1); } }
  @keyframes heartbeat {
    0%, 30%, 50%, 60%, 80% {
      transform: scale(1); }
    40%, 70% {
      transform: scale(1.1); } }
  .animate.heartbeat, .onHoverHeartbeat:hover, .trigger.heartbeat {
    -webkit-animation-name: heartbeat;
    animation-name: heartbeat; }
  @-webkit-keyframes panic {
    0%, 100% {
      -webkit-transform: scale(1) rotate(0); }
    10%, 60% {
      -webkit-transform: scale(1.1) rotate(-3deg); }
    20%, 40% {
      -webkit-transform: scale(1) rotate(-3deg); }
    30% {
      -webkit-transform: scale(1.1) rotate(3deg); }
    50%, 70%, 90% {
      -webkit-transform: scale(1) rotate(3deg); }
    80% {
      -webkit-transform: scale(1.1) rotate(-3deg); } }
  @keyframes panic {
    0%, 100% {
      transform: scale(1) rotate(0); }
    10%, 60% {
      transform: scale(1.1) rotate(-3deg); }
    20%, 40% {
      transform: scale(1) rotate(-3deg); }
    30% {
      transform: scale(1.1) rotate(3deg); }
    50%, 70%, 90% {
      transform: scale(1) rotate(3deg); }
    80% {
      transform: scale(1.1) rotate(-3deg); } }
  .animate.panic, .onHoverPanic:hover, .trigger.panic {
    -webkit-animation-name: panic;
    animation-name: panic; }
  @-webkit-keyframes explode {
    0% {
      -webkit-transform: scale(1); }
    20% {
      -webkit-transform: scale(0.3) rotate(-3deg); }
    60% {
      -webkit-transform: scale(0.3) rotate(-5deg); }
    30% {
      -webkit-transform: scale(0.4) rotate(8deg); }
    40% {
      -webkit-transform: scale(0.3) rotate(-11deg); }
    50% {
      -webkit-transform: scale(0.4) rotate(14deg); }
    70% {
      -webkit-transform: scale(0.2) rotate(17deg); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.2) rotate(-20deg); }
    100% {
      opacity: 0;
      -webkit-transform: scale(10) rotate(360deg); } }
  @keyframes explode {
    0% {
      opacity: 1;
      transform: scale(1); }
    20% {
      transform: scale(0.3) rotate(-3deg); }
    60% {
      transform: scale(0.3) rotate(-5deg); }
    30% {
      transform: scale(0.4) rotate(8deg); }
    40% {
      transform: scale(0.3) rotate(-11deg); }
    50% {
      transform: scale(0.4) rotate(14deg); }
    70% {
      transform: scale(0.2) rotate(17deg); }
    80% {
      opacity: 1;
      transform: scale(0.2) rotate(-20deg); }
    100% {
      opacity: 0;
      transform: scale(10) rotate(360deg); } }
  .animate.explode, .trigger.explode {
    -webkit-animation-name: explode;
    animation-name: explode; } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .animate, .onHoverBounce, .onHoverExplode, .onHoverFlash, .onHoverHeartbeat, .onHoverPanic, .onHoverPullback, .onHoverPulsate, .onHoverPulse, .onHoverShake, .onHoverSpin, .onHoverStrobe, .onHoverTada, .onHoverWave, .onHoverWobble, .trigger {
    opacity: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .ie .animate, .no-js .animate, .onHoverBounce, .onHoverExplode, .onHoverFlash, .onHoverHeartbeat, .onHoverPanic, .onHoverPullback, .onHoverPulsate, .onHoverPulse, .onHoverShake, .onHoverSpin, .onHoverStrobe, .onHoverTada, .onHoverWave, .onHoverWobble, .trigger {
    opacity: 1; }
  .animate.infinite, .onHoverBounce.infinite, .onHoverExplode.infinite, .onHoverFlash.infinite, .onHoverHeartbeat.infinite, .onHoverPanic.infinite, .onHoverPullback.infinite, .onHoverPulsate.infinite, .onHoverPulse.infinite, .onHoverShake.infinite, .onHoverSpin.infinite, .onHoverStrobe.infinite, .onHoverTada.infinite, .onHoverWave.infinite, .onHoverWobble.infinite, .trigger.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  .animate.fadeIn, .trigger.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn; }
  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(200px);
              transform: translateY(200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); } }
  .animate.fadeInUp, .trigger.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp; }
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-200px);
              transform: translateY(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); } }
  .animate.fadeInDown, .trigger.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown; }
  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); } }
  .animate.fadeInLeft, .trigger.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft; }
  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(200px);
              transform: translateX(200px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); } }
  .animate.fadeInRight, .trigger.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight; }
  @-webkit-keyframes fadeInUpLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateY(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInUpLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); } }
  .animate.fadeInUpLarge, .trigger.fadeInUpLarge {
    -webkit-animation-name: fadeInUpLarge;
    animation-name: fadeInUpLarge; }
  @-webkit-keyframes fadeInDownLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); } }
  @keyframes fadeInDownLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); } }
  .animate.fadeInDownLarge, .trigger.fadeInDownLarge {
    -webkit-animation-name: fadeInDownLarge;
    animation-name: fadeInDownLarge; }
  @-webkit-keyframes fadeInLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); } }
  .animate.fadeInLeftLarge, .trigger.fadeInLeftLarge {
    -webkit-animation-name: fadeInLeftLarge;
    animation-name: fadeInLeftLarge; }
  @-webkit-keyframes fadeInRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0); } }
  @keyframes fadeInRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); } }
  .animate.fadeInRightLarge, .trigger.fadeInRightLarge {
    -webkit-animation-name: fadeInRightLarge;
    animation-name: fadeInRightLarge; }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  @keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  .animate.fadeOut, .trigger.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut; }
  @-webkit-keyframes fadeOutUp {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200px); } }
  @keyframes fadeOutUp {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200px);
              transform: translateY(-200px); } }
  .animate.fadeOutUp, .trigger.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp; }
  @-webkit-keyframes fadeOutDown {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(200px); } }
  @keyframes fadeOutDown {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(200px);
              transform: translateY(200px); } }
  .animate.fadeOutDown, .trigger.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown; }
  @-webkit-keyframes fadeOutLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-200px); } }
  @keyframes fadeOutLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px); } }
  .animate.fadeOutLeft, .trigger.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft; }
  @-webkit-keyframes fadeOutRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(200px); } }
  @keyframes fadeOutRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(200px);
              transform: translateX(200px); } }
  .animate.fadeOutRight, .trigger.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight; }
  @-webkit-keyframes fadeOutUpLarge {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-1000px); } }
  @keyframes fadeOutUpLarge {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px); } }
  .animate.fadeOutUpLarge, .trigger.fadeOutUpLarge {
    -webkit-animation-name: fadeOutUpLarge;
    animation-name: fadeOutUpLarge; }
  @-webkit-keyframes fadeOutDownLarge {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(1000px); } }
  @keyframes fadeOutDownLarge {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px); } }
  .animate.fadeOutDownLarge, .trigger.fadeOutDownLarge {
    -webkit-animation-name: fadeOutDownLarge;
    animation-name: fadeOutDownLarge; }
  @-webkit-keyframes fadeOutLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-1000px); } }
  @keyframes fadeOutLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px); } }
  .animate.fadeOutLeftLarge, .trigger.fadeOutLeftLarge {
    -webkit-animation-name: fadeOutLeftLarge;
    animation-name: fadeOutLeftLarge; }
  @-webkit-keyframes fadeOutRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(1000px); } }
  @keyframes fadeOutRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px); } }
  .animate.fadeOutRightLarge, .trigger.fadeOutRightLarge {
    -webkit-animation-name: fadeOutRightLarge;
    animation-name: fadeOutRightLarge; }
  @-webkit-keyframes zoomIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1); } }
  @keyframes zoomIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .animate.zoomIn, .trigger.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn; }
  @-webkit-keyframes zoomInUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px);
              transform: scale(0) translateY(200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); } }
  .animate.zoomInUp, .trigger.zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp; }
  @-webkit-keyframes zoomInDown {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInDown {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px);
              transform: scale(0) translateY(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); } }
  .animate.zoomInDown, .trigger.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown; }
  @-webkit-keyframes zoomInLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-200px);
              transform: scale(0) translateX(-200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0); } }
  .animate.zoomInLeft, .trigger.zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft; }
  @-webkit-keyframes zoomInRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(200px);
              transform: scale(0) translateX(200px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0); } }
  .animate.zoomInRight, .trigger.zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight; }
  @-webkit-keyframes zoomInUpLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInUpLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(1000px);
              transform: scale(0) translateY(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); } }
  .animate.zoomInUpLarge, .trigger.zoomInUpLarge {
    -webkit-animation-name: zoomInUpLarge;
    animation-name: zoomInUpLarge; }
  @-webkit-keyframes zoomInDownLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes zoomInDownLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-1000px);
              transform: scale(0) translateY(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); } }
  .animate.zoomInDownLarge, .trigger.zoomInDownLarge {
    -webkit-animation-name: zoomInDownLarge;
    animation-name: zoomInDownLarge; }
  @-webkit-keyframes zoomInLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-1000px);
              transform: scale(0) translateX(-1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0); } }
  .animate.zoomInLeftLarge, .trigger.zoomInLeftLarge {
    -webkit-animation-name: zoomInLeftLarge;
    animation-name: zoomInLeftLarge; }
  @-webkit-keyframes zoomInRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes zoomInRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(1000px);
              transform: scale(0) translateX(1000px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0); } }
  .animate.zoomInRightLarge, .trigger.zoomInRightLarge {
    -webkit-animation-name: zoomInRightLarge;
    animation-name: zoomInRightLarge; }
  @-webkit-keyframes zoomOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0); } }
  @keyframes zoomOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0); } }
  .animate.zoomOut, .trigger.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut; }
  @-webkit-keyframes zoomOutUp {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px); } }
  @keyframes zoomOutUp {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px);
              transform: scale(0) translateY(-200px); } }
  .animate.zoomOutUp, .trigger.zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp; }
  @-webkit-keyframes zoomOutDown {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px); } }
  @keyframes zoomOutDown {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px);
              transform: scale(0) translateY(200px); } }
  .animate.zoomOutDown, .trigger.zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown; }
  @-webkit-keyframes zoomOutLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(-200px); } }
  @keyframes zoomOutLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0);
              transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(-200px);
              transform: scale(1) translateX(-200px); } }
  .animate.zoomOutLeft, .trigger.zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft; }
  @-webkit-keyframes zoomOutRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(200px); } }
  @keyframes zoomOutRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0);
              transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(200px);
              transform: scale(1) translateX(200px); } }
  .animate.zoomOutRight, .trigger.zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight; }
  @-webkit-keyframes zoomOutUpLarge {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-1000px); } }
  @keyframes zoomOutUpLarge {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-1000px);
              transform: scale(0) translateY(-1000px); } }
  .animate.zoomOutUpLarge, .trigger.zoomOutUpLarge {
    -webkit-animation-name: zoomOutUpLarge;
    animation-name: zoomOutUpLarge; }
  @-webkit-keyframes zoomOutDownLarge {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(1000px); } }
  @keyframes zoomOutDownLarge {
    0% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(1000px);
              transform: scale(0) translateY(1000px); } }
  .animate.zoomOutDownLarge, .trigger.zoomOutDownLarge {
    -webkit-animation-name: zoomOutDownLarge;
    animation-name: zoomOutDownLarge; }
  @-webkit-keyframes zoomOutLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(-1000px); } }
  @keyframes zoomOutLeftLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0);
              transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(-1000px);
              transform: scale(1) translateX(-1000px); } }
  .animate.zoomOutLeftLarge, .trigger.zoomOutLeftLarge {
    -webkit-animation-name: zoomOutLeftLarge;
    animation-name: zoomOutLeftLarge; }
  @-webkit-keyframes zoomOutRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(1000px); } }
  @keyframes zoomOutRightLarge {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(0);
              transform: scale(0) translateX(0); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(1000px);
              transform: scale(1) translateX(1000px); } }
  .animate.zoomOutRightLarge, .trigger.zoomOutRightLarge {
    -webkit-animation-name: zoomOutRightLarge;
    animation-name: zoomOutRightLarge; }
  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0); }
    50% {
      opacity: 1;
      -webkit-transform: scale(1.1); }
    70% {
      opacity: 1;
      -webkit-transform: scale(0.9); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1); } }
  @keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0); }
    50% {
      opacity: 1;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    70% {
      opacity: 1;
      -webkit-transform: scale(0.9);
              transform: scale(0.9); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); } }
  .animate.bounceIn, .trigger.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn; }
  @-webkit-keyframes bounceInUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateY(-40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes bounceInUp {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(200px);
              transform: scale(0) translateY(200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateY(-40px);
              transform: scale(1.1) translateY(-40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(20px);
              transform: scale(0.9) translateY(20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); } }
  .animate.bounceInUp, .trigger.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp; }
  @-webkit-keyframes bounceInDown {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateY(40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(-20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0); } }
  @keyframes bounceInDown {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateY(-200px);
              transform: scale(0) translateY(-200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateY(40px);
              transform: scale(1.1) translateY(40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateY(-20px);
              transform: scale(0.9) translateY(-20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); } }
  .animate.bounceInDown, .trigger.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown; }
  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateX(40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateX(-20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(-200px);
              transform: scale(0) translateX(-200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateX(40px);
              transform: scale(1.1) translateX(40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateX(-20px);
              transform: scale(0.9) translateX(-20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0); } }
  .animate.bounceInLeft, .trigger.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft; }
  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateX(-40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateX(20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0); } }
  @keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) translateX(200px);
              transform: scale(0) translateX(200px); }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.1) translateX(-40px);
              transform: scale(1.1) translateX(-40px); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.9) translateX(20px);
              transform: scale(0.9) translateX(20px); }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0); } }
  .animate.bounceInRight, .trigger.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight; }
  @-webkit-keyframes bounceOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1); }
    30% {
      opacity: 1;
      -webkit-transform: scale(1.1); }
    50% {
      opacity: 0;
      -webkit-transform: scale(0.9); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0); } }
  @keyframes bounceOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
    30% {
      opacity: 1;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    50% {
      opacity: 0;
      -webkit-transform: scale(0.9);
              transform: scale(0.9); }
    100% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0); } }
  .animate.bounceOut, .trigger.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut; }
  @-webkit-keyframes bounceOutUp {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateY(40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200px); } }
  @keyframes bounceOutUp {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateY(40px);
              transform: translateY(40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200px);
              transform: translateY(-200px); } }
  .animate.bounceOutUp, .trigger.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp; }
  @-webkit-keyframes bounceOutDown {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateY(-40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(200px); } }
  @keyframes bounceOutDown {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateY(200px);
              transform: translateY(200px); } }
  .animate.bounceOutDown, .trigger.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown; }
  @-webkit-keyframes bounceOutLeft {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateX(40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-200px); } }
  @keyframes bounceOutLeft {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateX(40px);
              transform: translateX(40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px); } }
  .animate.bounceOutLeft, .trigger.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft; }
  @-webkit-keyframes bounceOutRight {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateX(-40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateX(200px); } }
  @keyframes bounceOutRight {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    20% {
      opacity: 1;
      -webkit-transform: translateX(-40px);
              transform: translateX(-40px); }
    100% {
      opacity: 0;
      -webkit-transform: translateX(200px);
              transform: translateX(200px); } }
  .animate.bounceOutRight, .trigger.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight; }
  @-webkit-keyframes spinLeft {
    0% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(-380deg);
      opacity: 0; }
    100% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(0);
      opacity: 1; } }
  @keyframes spinLeft {
    0% {
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-transform: rotate(-380deg);
              transform: rotate(-380deg);
      opacity: 0; }
    100% {
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1; } }
  .animate.spinLeft, .trigger.spinLeft {
    -webkit-animation-name: spinLeft;
    animation-name: spinLeft; }
  @-webkit-keyframes spinRight {
    0% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(380deg);
      opacity: 0; }
    100% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(0);
      opacity: 1; } }
  @keyframes spinRight {
    0% {
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-transform: rotate(380deg);
              transform: rotate(380deg);
      opacity: 0; }
    100% {
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1; } }
  .animate.spinRight, .trigger.spinRight {
    -webkit-animation-name: spinRight;
    animation-name: spinRight; }
  @-webkit-keyframes flash {
    0%, 100%, 50% {
      opacity: 1; }
    25%, 75% {
      opacity: 0; } }
  @keyframes flash {
    0%, 100%, 50% {
      opacity: 1; }
    25%, 75% {
      opacity: 0; } }
  .animate.flash, .onHoverFlash:hover, .trigger.flash {
    -webkit-animation-name: flash;
    animation-name: flash; }
  @-webkit-keyframes strobe {
    0%, 100%, 20%, 40%, 60%, 80% {
      opacity: 1; }
    10%, 30%, 50%, 70%, 90% {
      opacity: 0; } }
  @keyframes strobe {
    0%, 100%, 20%, 40%, 60%, 80% {
      opacity: 1; }
    10%, 30%, 50%, 70%, 90% {
      opacity: 0; } }
  .animate.strobe, .onHoverStrobe:hover, .trigger.strobe {
    -webkit-animation-name: strobe;
    animation-name: strobe; }
  @-webkit-keyframes shake {
    0%, 100% {
      -webkit-transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% {
      -webkit-transform: translateX(-10px); }
    20%, 40%, 60%, 80% {
      -webkit-transform: translateX(10px); } }
  @keyframes shake {
    0%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% {
      -webkit-transform: translateX(-10px);
              transform: translateX(-10px); }
    20%, 40%, 60%, 80% {
      -webkit-transform: translateX(10px);
              transform: translateX(10px); } }
  .animate.shake, .onHoverShake:hover, .trigger.shake {
    -webkit-animation-name: shake;
    animation-name: shake; }
  @-webkit-keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
      -webkit-transform: translateY(0); }
    40% {
      -webkit-transform: translateY(-30px); }
    60% {
      -webkit-transform: translateY(-15px); } }
  @keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    40% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px); }
    60% {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px); } }
  .animate.bounce, .onHoverBounce:hover, .trigger.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce; }
  @-webkit-keyframes tada {
    0%, 100% {
      -webkit-transform: scale(1) rotate(0); }
    10%, 20% {
      -webkit-transform: scale(0.9) rotate(-3deg); }
    30%, 50%, 70%, 90% {
      -webkit-transform: scale(1.1) rotate(3deg); }
    40%, 60%, 80% {
      -webkit-transform: scale(1.1) rotate(-3deg); } }
  @keyframes tada {
    0%, 100% {
      -webkit-transform: scale(1) rotate(0);
              transform: scale(1) rotate(0); }
    10%, 20% {
      -webkit-transform: scale(0.9) rotate(-3deg);
              transform: scale(0.9) rotate(-3deg); }
    30%, 50%, 70%, 90% {
      -webkit-transform: scale(1.1) rotate(3deg);
              transform: scale(1.1) rotate(3deg); }
    40%, 60%, 80% {
      -webkit-transform: scale(1.1) rotate(-3deg);
              transform: scale(1.1) rotate(-3deg); } }
  .animate.tada, .onHoverTada:hover, .trigger.tada {
    -webkit-animation-name: tada;
    animation-name: tada; }
  @-webkit-keyframes wave {
    20% {
      -webkit-transform: rotate(15deg); }
    40% {
      -webkit-transform: rotate(-10deg); }
    60% {
      -webkit-transform: rotate(5deg); }
    80% {
      -webkit-transform: rotate(-5deg); }
    100% {
      -webkit-transform: rotate(0deg); } }
  @keyframes wave {
    20% {
      -webkit-transform: rotate(15deg);
              transform: rotate(15deg); }
    40% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg); }
    60% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg); }
    80% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg); }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); } }
  .animate.wave, .onHoverWave:hover, .trigger.wave {
    -webkit-animation-name: wave;
    animation-name: wave; }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  .animate.spin, .onHoverSpin:hover, .trigger.spin {
    -webkit-animation-name: spin;
    animation-name: spin; }
  @-webkit-keyframes pullback {
    0% {
      -webkit-transform: rotate(0deg); }
    20%, 30% {
      -webkit-transform: rotate(-45deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes pullback {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    20%, 30% {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  .animate.pullback, .onHoverPullback:hover, .trigger.pullback {
    -webkit-animation-name: pullback;
    animation-name: pullback; }
  @-webkit-keyframes wobble {
    0% {
      -webkit-transform: translateX(0px); }
    15% {
      -webkit-transform: translateX(-25px) rotate(-5deg); }
    30% {
      -webkit-transform: translateX(20px) rotate(3deg); }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3deg); }
    60% {
      -webkit-transform: translateX(10px) rotate(2deg); }
    75% {
      -webkit-transform: translateX(-5px) rotate(-1deg); }
    100% {
      -webkit-transform: translateX(0px); } }
  @keyframes wobble {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px); }
    15% {
      -webkit-transform: translateX(-25px) rotate(-5deg);
              transform: translateX(-25px) rotate(-5deg); }
    30% {
      -webkit-transform: translateX(20px) rotate(3deg);
              transform: translateX(20px) rotate(3deg); }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3deg);
              transform: translateX(-15px) rotate(-3deg); }
    60% {
      -webkit-transform: translateX(10px) rotate(2deg);
              transform: translateX(10px) rotate(2deg); }
    75% {
      -webkit-transform: translateX(-5px) rotate(-1deg);
              transform: translateX(-5px) rotate(-1deg); }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px); } }
  .animate.wobble, .onHoverWobble:hover, .trigger.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble; }
  @-webkit-keyframes pulse {
    0%, 100% {
      -webkit-transform: scale(1); }
    50% {
      -webkit-transform: scale(1.1); } }
  @keyframes pulse {
    0%, 100% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); } }
  .animate.pulse, .onHoverPulse:hover, .trigger.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse; }
  @-webkit-keyframes pulsate {
    0%, 100%, 50% {
      -webkit-transform: scale(1); }
    25%, 75% {
      -webkit-transform: scale(1.1); } }
  @keyframes pulsate {
    0%, 100%, 50% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    25%, 75% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); } }
  .animate.pulsate, .onHoverPulsate:hover, .trigger.pulsate {
    -webkit-animation-name: pulsate;
    animation-name: pulsate; }
  @-webkit-keyframes heartbeat {
    0%, 30%, 50%, 60%, 80% {
      -webkit-transform: scale(1); }
    40%, 70% {
      -webkit-transform: scale(1.1); } }
  @keyframes heartbeat {
    0%, 30%, 50%, 60%, 80% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    40%, 70% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); } }
  .animate.heartbeat, .onHoverHeartbeat:hover, .trigger.heartbeat {
    -webkit-animation-name: heartbeat;
    animation-name: heartbeat; }
  @-webkit-keyframes panic {
    0%, 100% {
      -webkit-transform: scale(1) rotate(0); }
    10%, 60% {
      -webkit-transform: scale(1.1) rotate(-3deg); }
    20%, 40% {
      -webkit-transform: scale(1) rotate(-3deg); }
    30% {
      -webkit-transform: scale(1.1) rotate(3deg); }
    50%, 70%, 90% {
      -webkit-transform: scale(1) rotate(3deg); }
    80% {
      -webkit-transform: scale(1.1) rotate(-3deg); } }
  @keyframes panic {
    0%, 100% {
      -webkit-transform: scale(1) rotate(0);
              transform: scale(1) rotate(0); }
    10%, 60% {
      -webkit-transform: scale(1.1) rotate(-3deg);
              transform: scale(1.1) rotate(-3deg); }
    20%, 40% {
      -webkit-transform: scale(1) rotate(-3deg);
              transform: scale(1) rotate(-3deg); }
    30% {
      -webkit-transform: scale(1.1) rotate(3deg);
              transform: scale(1.1) rotate(3deg); }
    50%, 70%, 90% {
      -webkit-transform: scale(1) rotate(3deg);
              transform: scale(1) rotate(3deg); }
    80% {
      -webkit-transform: scale(1.1) rotate(-3deg);
              transform: scale(1.1) rotate(-3deg); } }
  .animate.panic, .onHoverPanic:hover, .trigger.panic {
    -webkit-animation-name: panic;
    animation-name: panic; }
  @-webkit-keyframes explode {
    0% {
      -webkit-transform: scale(1); }
    20% {
      -webkit-transform: scale(0.3) rotate(-3deg); }
    60% {
      -webkit-transform: scale(0.3) rotate(-5deg); }
    30% {
      -webkit-transform: scale(0.4) rotate(8deg); }
    40% {
      -webkit-transform: scale(0.3) rotate(-11deg); }
    50% {
      -webkit-transform: scale(0.4) rotate(14deg); }
    70% {
      -webkit-transform: scale(0.2) rotate(17deg); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.2) rotate(-20deg); }
    100% {
      opacity: 0;
      -webkit-transform: scale(10) rotate(360deg); } }
  @keyframes explode {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
    20% {
      -webkit-transform: scale(0.3) rotate(-3deg);
              transform: scale(0.3) rotate(-3deg); }
    60% {
      -webkit-transform: scale(0.3) rotate(-5deg);
              transform: scale(0.3) rotate(-5deg); }
    30% {
      -webkit-transform: scale(0.4) rotate(8deg);
              transform: scale(0.4) rotate(8deg); }
    40% {
      -webkit-transform: scale(0.3) rotate(-11deg);
              transform: scale(0.3) rotate(-11deg); }
    50% {
      -webkit-transform: scale(0.4) rotate(14deg);
              transform: scale(0.4) rotate(14deg); }
    70% {
      -webkit-transform: scale(0.2) rotate(17deg);
              transform: scale(0.2) rotate(17deg); }
    80% {
      opacity: 1;
      -webkit-transform: scale(0.2) rotate(-20deg);
              transform: scale(0.2) rotate(-20deg); }
    100% {
      opacity: 0;
      -webkit-transform: scale(10) rotate(360deg);
              transform: scale(10) rotate(360deg); } }
  .animate.explode, .trigger.explode {
    -webkit-animation-name: explode;
    animation-name: explode; } }